import React from 'react'
import { NavLink } from 'react-router-dom'

import { Box, Text, Flex, Stack, Image, Divider } from '@chakra-ui/react'

import LogoFNDE from '@/assets/svg/logo-fnde.svg'
import LogoLIP from '@/assets/svg/logo-lip-w.svg'
import LogoMEC from '@/assets/svg/logo-mec.svg'

import NavItem from './parts/NavItem'

const Sidebar = () => {
  return (
    <Stack
      p={14}
      gap={6}
      bg="brand.primary.dark_2"
      color="white"
      h="full"
      maxW={{
        base: 'full',
        lg: '27.5rem',
      }}
      position={{ base: 'unset', lg: 'fixed' }}
      justify="space-between"
      overflowY="auto"
      sx={{
        '&::-webkit-scrollbar': {
          width: '6px',
          backgroundColor: 'brand.neutral.light_2',
          borderRight: '1px solid #E8E8E8',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'brand.neutral.light_1',
          borderRadius: '8px',
        },
      }}
    >
      <Stack gap={6}>
        <Box>
          <Image src={LogoLIP} alt="Logo LIP" h="48px" />
        </Box>
        <Text fontWeight="medium" fontSize="5xl">
          Suporte
        </Text>
        <Text fontSize="md">
          Possui alguma dúvida?
          <br /> Entre em contato com nossa equipe de suporte para receber
          assistência.
        </Text>
        <Stack>
          <NavItem name="Formulário de contato" to="/contato" />
          <NavItem name="Tirar dúvidas - FAQ" to="/duvidas" />
          <NavItem name="Ler manual" to="/manual" />
          <NavItem name="Vídeos tutoriais" to="/tutoriais" />
        </Stack>
      </Stack>
      <Flex align="center" gap={3}>
        <Image src={LogoFNDE} alt="Logo FNDE" h="36px" />
        <Image src={LogoMEC} alt="Logo MEC" h="48px" />
      </Flex>
    </Stack>
  )
}

export default Sidebar
